import React from 'react';
import MarketCarousel from '../components/MarketCarousel';
import MarketsList from '../components/MarketsList';
import MarketNews from '../components/MarketNews';

export default function dashboard() {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <MarketCarousel />
          </div>
        </div>
      </div>
      <MarketsList />
      <MarketNews />
    </>
  );
}

import React from 'react';
import MarketCarousel from '../components/MarketCarousel';
import MarketsList from '../components/MarketsList';
import MarketNews from '../components/MarketNews';

export default function markets() {
  return (
    <>
      <MarketsList />
    </>
  );
}
